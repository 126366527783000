<template>
    <div class="container" style="padding-top: 10%;">
    </div>
</template>

<script>
import store from '@/store';
import auth from '@/auth';

export default {
  beforeCreate () {
    store.commit('ws_status', false);
    auth.logout(this, 'login');
  }
};
</script>
